import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';

const PrivacyPage = () => {
  const { t } = useTranslation('data-protection');

  return (
    <PageWrapper hero={false}>
      <SEO title={t('title')} />
      <HeroState clear />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }}>
            <Trans i18nKey="content" ns="data-protection">
              <h1>Datenschutz</h1>
              <p>
                Wir von der Caffè A Mano GmbH, Kleinhohenheimerstr. 5, 70619
                Stuttgart Deutschland (im Folgenden: „
                <strong>Caffè A Mano</strong>“) nehmen den Schutz Ihrer
                personenbezogenen Daten sehr ernst. Wir verarbeiten Ihre
                personenbezogenen Daten ausschließlich in Übereinstimmung mit
                dieser Datenschutzerklärung sowie den einschlägigen gesetzlichen
                Bestimmungen, insbesondere der Europäischen
                Datenschutzgrundverordnung (im Folgenden: „
                <strong>DS-GVO</strong>
                “).
              </p>
              <h2>1. Nutzung unserer Website</h2>
              <h3>1.1 Allgemeines zur Nutzung unserer Website</h3>
              <p>
                Bei der Nutzung unserer Website verarbeiten wir solche
                personenbezogenen Daten, die Ihr Browser an unseren Server
                übermittelt. Hierzu zählen die IP-Adresse, Datum und Uhrzeit der
                Anfrage, Zeitzonendifferenz zur Greenwich Mean Time (GMT),
                Inhalt der Anforderung (die konkrete Seite), den Zugriffsstatus
                bzw. HTTP-Statuscode, die jeweils übertragene Datenmenge, die
                Website, von der die Anforderung kommt, der verwendete Browser,
                das Betriebssystem und dessen Oberfläche sowie Sprache und
                Version der Browsersoftware.
              </p>
              <p>
                Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1
                lit. f) DS-GVO. Die Verarbeitung der Daten ermöglicht die
                Darstellung unserer Website und dient insbesondere auch der
                Gewährleistung ihrer Stabilität und Sicherheit.
              </p>
              <h3>1.2 Cookies</h3>
              <p>
                Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
                unserer Website Cookies auf Ihrem Rechner gespeichert. Bei
                Cookies handelt es sich um kleine Textdateien, die auf Ihrer
                Festplatte dem von Ihnen verwendeten Browser zugeordnet
                gespeichert werden. Dadurch werden demjenigen, der den Cookie
                setzt, bestimmte Informationen übermittelt. Cookies können keine
                Programme ausführen oder Viren auf Ihren Computer übertragen.
                Sie dienen dazu, unsere Website insgesamt nutzerfreundlicher und
                effektiver zu machen.
              </p>
              <p>
                Sie können der Speicherung der Cookies durch entsprechende
                Einstellungen in Ihrem Browser verhindern, etwa indem Sie das
                automatische Setzen von Cookies generell deaktivieren. In diesem
                Fall können Funktionen dieser Website unter Umständen jedoch
                nicht mehr richtig genutzt werden. Konkret nutzen wir auf
                unserer Website die folgenden Cookies:
              </p>
              <h2>Google-Analytics</h2>
              <p>
                Auf unserer Website benutzen wir Google Analytics mit der
                Erweiterung „anonymizeIp“, einen Webanalysedienst der Google
                Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                (im Folgenden: „Google“). Hierdurch werden IP-Adressen von
                Google innerhalb der Mitgliedsstaaten der Europäischen Union
                oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen Wirtschaftsraum gekürzt weiterverarbeitet. Nur in
                Ausnahmefällen wird die volle IP-Adresse an einen Server von
                Google in den USA übertragen und dort gekürzt. Ein Personenbezug
                kann damit ausgeschlossen werden. Soweit den über Sie erhobenen
                Daten ein Personenbezug zukommt, wird dieser sofort
                ausgeschlossen und die personenbezogenen Daten damit umgehend
                gelöscht. Google hat sich dem EU-US Privacy Shield unterworfen,{' '}
                <a
                  href="https://www.privacyshield.gov/EU-US-Framework."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.privacyshield.gov/EU-US-Framework.
                </a>
              </p>
              <p>
                Rechtsgrundlage für unsere Nutzung von Google Analytics ist Art.
                6 Abs. 1 S. 1 lit. f) DS-GVO. In unserem Auftrag wird Google die
                erlangten Daten benutzen, um Ihre Nutzung unserer Website
                auszuwerten, um Reports über die Website-Aktivitäten
                zusammenzustellen und um weitere mit der Website-Nutzung und der
                Internetnutzung verbundene Dienstleistungen gegenüber uns zu
                erbringen. Die Verarbeitung bezweckt die Analyse und Optimierung
                unserer Website.
              </p>
              <p>
                Über die Möglichkeit der Browser-Einstellung hinaus können Sie
                die Erfassung der durch das Cookie erzeugten und auf Ihre
                Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
                sowie deren Übertragung an und deren Verarbeitung durch Google
                verhindern, indem sie das unter dem folgenden Link verfügbare
                Browser-Plug-in herunterladen und installieren:{' '}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                .
              </p>
              <p>
                Nähere Informationen über die Datenerhebung und -verarbeitung
                durch Google können Sie den Datenschutzhinweisen von Google
                entnehmen, die Sie unter
                <a
                  href="https://www.google.com/policies/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.google.com/policies/privacy
                </a>{' '}
                abrufen können.
              </p>
              <p>
                Nach Ablauf eines bestimmten Zeitraums werden die Cookies
                automatisch gelöscht Sie können gespeicherte Cookies darüber
                hinaus jederzeit in Ihren Browsereinstellungen selbst löschen.
              </p>
              <h3>1.3 Social Media</h3>
              <p>
                Wir verwenden keine sogenannten Social-Media-Plug-Ins. Wir
                bieten Ihnen jedoch an verschiedenen Stellen auf unserer Website
                die Möglichkeit an, unseren Auftritt in sozialen Netzwerken wie
                Instagram zu besuchen. Wenn Sie auf das Logo oder den Namen
                eines sozialen Netzwerks klicken, werden Sie über einen Link auf
                unseren jeweiligen Auftritt weitergeleitet.
              </p>
              <p>
                Bevor Sie auf die Logos oder Links klicken, wodurch Sie auf die
                jeweilige Website des sozialen Netzwerks weitergeleitet werden,
                werden keine personenbezogenen Daten an die sozialen Netzwerke
                übermittelt. Die Möglichkeit, dass personenbezogene Daten an das
                jeweilige soziale Netzwerk übermittelt und von diesem
                verarbeitet werden, besteht erst ab dem Zeitpunkt, zu dem Sie
                auf unserer Website auf das jeweilige Logo klicken und zur
                Website des sozialen Netzwerks weitergeleitet werden. Zur
                Verarbeitung von personenbezogenen Daten kommt es insbesondere,
                wenn Sie mit Ihrem jeweiligen Social-Media-Account eingeloggt
                sind und die Inhalte mit Ihrem Account auf den sozialen
                Netzwerken posten. Darüber hinaus können jedoch auch Daten – wie
                etwa Ihre IP-Adresse – verarbeitet werden, wenn Sie keinen
                Social-Media-Account besitzen.
              </p>
              <p>
                Wir haben weder Einfluss auf die erhobenen Daten und
                Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der
                Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen
                bekannt. Auch zur Löschung der erhobenen Daten durch das
                jeweilige soziale Netzwerk liegen uns keine Informationen vor.
              </p>
              <p>
                Weitere Informationen zu Zweck und Umfang der Datenerhebung und
                ihrer Verarbeitung erhalten Sie in der Datenschutzerklärung des
                jeweiligen Netzwerks. Dort erhalten Sie auch weitere
                Informationen zu Ihren diesbezüglichen Rechten und
                Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:
              </p>
              <p>
                Instagram:{' '}
                <a
                  href="https://www.facebook.com/help/instagram/155833707900388/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/help/instagram/155833707900388/
                </a>
              </p>
              <h3>1.4 Newsletter</h3>
              <p>
                Sie haben auf unserer Website sowie bei verschiedenen anderen
                Gelegenheiten die Möglichkeit, unseren Newsletter zu bestellen.
                Unser Newsletter enthält Neuigkeiten und Informationen zu
                unseren Produkten, Branchennews und nützliche Tipps rund um das
                Thema Kaffee. Zur Anmeldung ist alleine die Angabe Ihrer
                E-Mail-Adresse erforderlich. Sie können darüber hinaus weitere
                Angaben – wie beispielsweise Ihren Namen oder Ihre Firma –
                freiwillig angeben.
              </p>
              <p>
                Bitte haben Sie Verständnis, dass unser Newsletter aus
                rechtlichen Gründen nur von Personen ab einem Alter von 16
                Jahren abonniert werden darf.
              </p>
              <p>
                Die Anmeldung für den Newsletter erfolgt nach dem sogenannten
                Double-Opt-In-Verfahren. Nach Eingabe Ihrer E-Mail-Adresse und
                ggf. Ihrer weiteren Daten senden wir Ihnen an diese Adresse eine
                E-Mail, mit der wir Sie um die Bestätigung Ihrer Anmeldung zum
                Newsletter bitten. Erst wenn Sie die Anmeldung durch Anklicken
                eines entsprechenden Links bestätigen, werden Sie in unseren
                Verteiler aufgenommen und erhalten ab diesem Zeitpunkt unseren
                Newsletter. Erfolgt die Bestätigung der Anmeldung nicht
                innerhalb von 72 Stunden, werden wir Ihre Informationen sperren
                und sie nach einem Monat löschen.
              </p>
              <p>
                Wir speichern bei der Anmeldung die von Ihnen verwendete
                IP-Adresse, den Zeitpunkt der Anmeldung und den Zeitpunkt der
                Bestätigung der Anmeldung. Dies erfolgt auf Grundlage von Art. 6
                Abs. 1 S. 1 lit. f) DS-GVO und verfolgt den Zweck, Ihre
                Anmeldung nachweisen und gegebenenfalls einen möglichen
                Missbrauch Ihrer personenbezogenen Daten aufklären zu können.
              </p>
              <p>
                Im Falle der Bestätigung der Anmeldung zum Newsletter speichern
                wir Ihre E-Mail-Adresse sowie gegebenenfalls Ihre weiteren
                freiwilligen Angaben gemäß Art. 6 Abs. 1 S. 1 lit. a) DS-GVO, um
                Ihnen unseren Newsletter zusenden zu können.
              </p>
              <p>
                Ihre Einwilligung in die Übersendung des Newsletters können Sie
                jederzeit widerrufen und den Newsletter abbestellen. Den
                Widerruf können Sie durch Klick auf den in jeder
                Newsletter-E-Mail bereitgestellten Link, per E-Mail an{' '}
                <a href={'mailto:info@manument.com'}>info@manument.com</a> oder
                durch eine Nachricht an die im Impressum angegebenen
                Kontaktdaten erklären.
              </p>
              <h3>1.5 Kontaktaufnahme per E-Mail</h3>
              <p>
                Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von
                Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, gegebenenfalls
                Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre
                Fragen zu beantworten. Die in diesem Zusammenhang anfallenden
                Daten löschen wir, nachdem die Speicherung nicht mehr
                erforderlich ist, oder schränken die Verarbeitung ein, falls
                gesetzliche Aufbewahrungspflichten bestehen.
              </p>
              <p>
                Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1
                S. 1 lit. b) DS-GVO. Sie dient ausschließlich der Bearbeitung
                und Beantwortung Ihrer Anfrage.
              </p>
              <h2>2. Weitergabe von Daten</h2>
              <p>
                Die von uns erhobenen personenbezogenen Daten geben wir nur dann
                an Dritte weiter, wenn dies datenschutzrechtlich gerechtfertigt
                ist. Konkrete Informationen zu Umfang und Empfängern finden Sie
                in den vorstehenden Bestimmungen. Im Übrigen geben wir Ihre
                personenbezogenen Daten ausschließlich an andere Unternehmen und
                Einzelpersonen heraus, die wir mit der Erfüllung von einzelnen
                Aufgaben und Dienstleistungen für uns beauftragt haben. Die
                Weitergabe Ihrer personenbezogenen Daten an diese Unternehmen
                beschränkt sich auf das zur Erfüllung ihrer Tätigkeit notwendige
                Maß. Diese Unternehmen sind gleichsam an die
                datenschutzrechtlichen Regelungen gebunden, wurden von uns
                sorgsam ausgewählt und werden von uns regelmäßig überwacht.
                Darüber hinaus geben wir Ihre personenbezogenen Daten nur dann
                an Dritte weiter, soweit wir gesetzlich oder per
                Gerichtsbeschluss dazu verpflichtet sind.
              </p>
              <h2>3. Datensicherheit und Speicherdauer</h2>
              <p>
                Zum Schutz Ihrer personenbezogenen Daten ergreifen wir
                Maßnahmen, um Ihre Daten vor einem unbefugten Zugriff, vor
                Verlust, vor Missbrauch oder vor Zerstörung zu schützen. Wir
                weisen jedoch darauf hin, dass die Datenübertragung im Internet
                (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                durch Dritte ist nicht möglich.
              </p>
              <p>
                Sämtliche Daten werden zwischen Ihrem Browser und unserem Server
                verschlüsselt übertragen.
              </p>
              <p>
                Personenbezogene Daten werden nur für den Zeitraum, der zur
                Erreichung des Speicherungszwecks erforderlich ist, oder sofern
                dies durch den Gesetzgeber vorgesehen wurde, gespeichert.
                Dementsprechend werden die personenbezogenen Daten routinemäßig
                gesperrt oder gelöscht, wenn der Speicherungszweck entfällt oder
                eine gesetzliche Frist abläuft.
              </p>
              <h2>4. Ihre Rechte / Kontakt</h2>
              <p>
                Sie haben gegenüber Caffè A Mano folgende Rechte bezüglich Ihrer
                von uns erhobenen personenbezogenen Daten:
              </p>
              <ul>
                <li>Recht auf Auskunft</li>
                <li>Recht auf Berichtigung oder Löschung</li>
                <li>Recht auf Einschränkung der Verarbeitung</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung</li>
                <li>Recht auf Datenübertragbarkeit.</li>
              </ul>
              <p>
                Falls Sie eine Einwilligung zur Verarbeitung Ihrer
                personenbezogenen Daten erteilt haben, können Sie diese
                jederzeit widerrufen. Hiervon wird die Rechtmäßigkeit der
                aufgrund der Einwilligung bis zum Widerruf erfolgten
                Verarbeitung nicht berührt.
              </p>
              <p>
                Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf
                die Interessenabwägung (Art. 6 Abs. 1 S. 1 lit. f) DS-GVO)
                stützen, können Sie Widerspruch gegen die Verarbeitung einlegen.
                Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung
                der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie
                von uns durchgeführt verarbeiten sollten. Im Falle Ihres
                begründeten Widerspruchs prüfen wir die Sachlage und werden
                entweder die Datenverarbeitung einstellen bzw. anpassen oder
                Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen,
                aufgrund derer wir die Verarbeitung fortführen.
              </p>
              <p>
                Selbstverständlich können Sie der Verarbeitung Ihrer
                personenbezogenen Daten für Zwecke der Werbung und Datenanalyse
                jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie
                uns unter folgenden Kontaktdaten informieren: Caffè A Mano GmbH,
                Kleinhohenheimerstr. 5, 70619 Stuttgart, Deutschland, E-Mail:{' '}
                <a href={'mailto:info@manument.com'}>info@manument.com</a>.
              </p>
              <p>
                Sie haben ferner das Recht, sich bei einer
                Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten durch uns zu beschweren.
              </p>
              <p>
                Verantwortlicher im Sinne des Art. 4 Abs. 7 DS-GVO ist Caffè A
                Mano GmbH, Kleinhohenheimerstr. 5, 70619 Stuttgart, Deutschland,
                E-Mail:{' '}
                <a href={'mailto:info@manument.com'}>info@manument.com</a>{' '}
                (siehe auch unter Impressum).
              </p>
            </Trans>
          </ContentColumn>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default PrivacyPage;
